.active {
  background-color: #ffffff;
  color: black;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(45deg, #ee6326, #f98c5d);
  color: black;
  position: sticky;
  top: 0;
}

nav .title {
  font-size: 2.5rem;
  margin: 1rem;
  text-decoration: none;
  color: rgb(0 0 0);
  font-family: fantasy;
  box-shadow: -6px 8px 7px rgba(0, 0, 64, 0.5);
  text-shadow: -2px 2px 5px rgba(228, 255, 255, 0.8);
  padding-left: 17px;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: rgb(0, 0, 0);
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
}
nav ul li a img {
  height: 1.9rem;
  width: 1.9rem;
}

nav ul li a:not(.active):hover {
  background-color: #000000;
  color: white;
}
nav ul {
  display: flex;
  align-items: center;
  margin: 1rem;
}
nav .menu {
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .menu span {
  height: 0.4rem;
  width: 100%;
  background-color: white;
  border-radius: 0.2rem;
}

@media (max-width: 480px) {
  nav .menu {
    display: flex;
  }
  nav {
    flex-direction: column;
    align-items: start;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }

  nav ul.open {
    display: flex;
  }
  nav ul li {
    width: 100%;
    text-align: center;
  }

  nav ul li a {
    margin: 0.2rem 0.5rem;
  }
}
img.active {
  background: #000;
  border-radius: 20px;
  box-shadow: 0px 0 10px rgb(255 21 21);
}


.overview-text {
  font-size: 1.5rem; /* Increase the font size */
  text-align: center; /* Center the text */
  max-width: 1200px; /* Ensure it doesn't stretch too wide */
  color: #333;
  line-height: 1.6;
}

.text-section {
  padding: 20px; /* Adjust padding as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}
