.p-button.p-button-outlined.custom-file {
    color: #0000ff;
    border: 2px solid;
}

/* .p-button.p-button-outlined.custom-file:hover {
    background-color: transparent;
    color: #000000;
} */

.p-button.p-button-success.p-button-outlined.custom-uploader {
    color: #008d07;
    border: 2px solid;
}

.p-button.p-button-danger.p-button-outlined.custom-cancel {
    color: #dc0000;
    border: 2px solid;
}

.p-tag.custom-tag {
    background: #6366F1;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 6px;
}