body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
  }
  
  .name {
    text-align: center;
    align-self: center;
    margin-bottom: 20px; /* Optional: Add some space between the text and the bottom of the page */
  }
  