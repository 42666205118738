.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  /* border: 2px solid red; */
  margin: 1rem;
  margin-top: -1rem;
  height: 100vh;
}

.rulelist td .start_button {
  background: none;
  color: black;
  border: none;
  padding: 0.25rem; /* Adjust padding as needed */
}

.maindiv .rulelist {
  width: 100%;
  max-width: 66rem;
  margin: auto;
  box-sizing: border-box;
  position: relative;
  margin-top: 2rem;
}

.pagination-wrapper {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

/* New CSS for the Actions column */
.actions-column {
  white-space: nowrap;
}

.actions-column .start_button {
  display: inline-block; /* Align buttons horizontally */
  margin-right: 0.5rem; /* Add some space between buttons */
}
.red {
  color: red;
  width: 60px;
}
.box-view {
  width: 250px;
}
.green {
  color: green;
  width: 60px;
}

.show {
  display: inline-block;
}
.hide {
  display: none;
}
.box-170 {
  width: 170px;
}
