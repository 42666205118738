.p-filter-column{
    width: 100%
}


input.p-inputtext.p-component.p-column-filter {
    width: 200px;
    height: 40px;
}

.p-datatable-tfoot{
    display:none
}

.p-column-header-content{
    color: #000000;
}

.p-column-title{
    /* color: #f00; */
    font-family: 'Arial Rounded MT Bold',Serif, "Times New Roman", Times, "Arial";
    font-size: 19px;
}
.p-icon{
    color: #000000;
}


.input-width {
    width: 550px;
}

.box {
    width: 620px;
}
.input-container {
    display: flex;
    align-items: center;
  }


.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-family: 'Arial Rounded MT Bold',Serif, "Times New Roman", Times, "Arial";
    font-size : 18.5px;
    color: #000000;
}

.input-container-1 {
    display: flex;
    align-items: center;
  }



.input-number {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.input-number label {
    margin-bottom: 5px;
}