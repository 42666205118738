.containerv1 {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 100px;  
    background: #F5F5F5;
    padding-bottom: 12px;  
    width: 300px !important;  
    box-shadow: -2.5px -2.5px 5px rgba(0, 0, 0, 0.2), 3.5px 3.5px 3.5px #FF8A00;  
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4.5px;  
    width: 100%;
    margin-top: -17px;  
}

.logoimage {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4.5px;  
    margin-top: 15px;  
}

.logoimage img {
    width: 85.5px;  
    height: 68px;  
}

.text {
    color: orangered;
    font-size: 24px;  
    font-weight: 700;
}

.underline {
    width: 30.5px;  
    height: 3px;  
    background: orangered;
    border-radius: 4.5px;  
}

.inputs {
    margin-top: 27.5px;  
    display: flex;
    flex-direction: column;
    gap: 14px;  
}

.input {
    display: flex;
    align-items: center;
    margin: auto;
    width: 240px;  
    height: 40px;  
    background: #D9D9D9;
    border-radius: 3px;  
    border-bottom: 1px solid #df8978;  
    
}

.input img {
    margin: 0px 15px;  
    height: 12px;
}

.input input {
    height: 25px;  
    width: 200px;  
    background: transparent;
    border: none;
    outline: none;
    font-size: 10px;  
}
.input:hover{
    /* border-bottom: 1px solid #ff2a00; */
    /* box-shadow: 0px 2px 5px rgb(234 144 81), 0px 1px 0px #ffffff00; */
    box-shadow: 0px 1px 1px rgb(228 77 77);
    
}

.forgot-Password {
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: -17px;  
    height: 40px;  
    font-size: 10px;  
    width: 235px;  
}

.forgot-Password span {
    color: #2f00ff;
    cursor: pointer;
    text-decoration-line: underline;
}

.submit-container {
    display: flex;
    gap: 15px;  
    margin: 12.5px auto;  
}

.submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;  
    height: 32.5px;  
    color: #fff;
    background: #fc4c00d1;
    border-radius: 5px;  
    font-size: 12px;  
    font-weight: 700;
    border: none;
    font-family: monospace;
}

.grey {
    background: #eaeaea;
    color: #676767;
}

.error-message {
    color: red;
}

.register-account {
    display: flex;
    gap: 15px;  
    margin: 12.5px auto;  
}

.register {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;  
    height: 32.5px;  
    color: #fb6a2c;
    background: #f5f5f5;
    border-radius: 5px;  
    font-size: 12px;  
    font-weight: 700;
    border: 1px solid #fb6a2c;  
    font-family: monospace;
}
