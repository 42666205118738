/* src/SignupPage.css
.signup-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #f9f9f9;
    min-height: 100vh;
  }
  
  .signup-left {
    flex: 1;
    max-width: 400px;
    margin: 20px;
    text-align: left;
  }
  
  .signup-right {
    flex: 1;
    max-width: 400px;
    margin: 20px;
    background-color: #fff;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .signup-right h3 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .btn-block {
    width: 100%;
  }
  
  .invalid-feedback {
    color: red;
  }
  
  a {
    color: #0073bb;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
   */
/* SignupPage.css */

.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Full height */
  background-color: #f0f0f0; /* Light gray background */
  font-family: Arial, sans-serif; /* Font family */
}

.signup-form {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  width: 400px; /* Set a fixed width */
}

.signup-form h3 {
  font-size: 24px; /* Larger font size for the header */
  font-weight: 600; /* Semi-bold font */
  margin-bottom: 1.5rem; /* Spacing below the header */
  text-align: center; /* Center align the header */
}

.form-group {
  margin-bottom: 1.5rem; /* Space between form groups */
}

label {
  font-size: 14px; /* Font size for labels */
  color: #333; /* Dark gray color for labels */
}

.form-control {
  height: 38px; /* Height of the input fields */
  font-size: 14px; /* Font size for input */
  padding: 0.375rem 0.75rem; /* Padding inside input */
  border: 1px solid #dcdcdc; /* Light border */
  border-radius: 4px; /* Slightly rounded corners */
  transition: border-color 0.2s; /* Smooth transition for border color */
}

.form-control:focus {
  border-color: #ff9900; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(255, 153, 0, 0.5); /* Slight shadow on focus */
}

.is-invalid {
  border-color: red; /* Red border for invalid inputs */
}

.invalid-feedback {
  display: block; /* Ensure error message is displayed as block */
  color: red; /* Error message color */
  font-size: 12px; /* Font size for error messages */
}

.btn-warning {
  background-color: #ff9900; /* AWS orange color */
  border: none; /* Remove border */
  color: white; /* White text color */
  padding: 0.5rem; /* Padding for button */
  font-size: 16px; /* Larger font size for buttons */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.2s; /* Smooth transition for hover effect */
}

.btn-warning:hover {
  background-color: #e68a00; /* Darker shade on hover */
}

.text-center {
  margin: 1rem 0; /* Margin for the 'OR' divider */
}

.btn-outline-secondary {
  color: #6c757d; /* Default text color */
  border-color: #6c757d; /* Default border color */
}

.btn-outline-secondary:hover {
  background-color: #e9ecef; /* Light gray on hover */
}

.alert {
  margin-top: 1rem; /* Margin above success/error message */
}

.flex {
  display: flex;
}
.justify-content-between {
  justify-content: space-between;
}
.w-full {
  width: 100%;
}

.fileBtn .p-button {
  background: none; /* Override PrimeReact's default background */
  /* border: none !important;  */
  color: #6c757d;
  border-color: #d1d5db;
}
