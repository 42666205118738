.Header {
  text-align: center;
}

.card1 {
  width: 40%; /* 80% of the parent width */
  max-width: 1000px; /* Maximum width */
  height: auto;
  padding: 2px;
  margin: 20px auto;
  margin-top: 2rem;
}

.spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 35rem; /* Adjust the gap as needed */
}

.card2 {
  width: 80%; /* 80% of the parent width */
  max-width: 1000px; /* Maximum width */
  height: auto;
  padding: 2px;
  margin: 20px auto;
}

.datafetching {
  background-color: black;
  color: azure;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* You can adjust the height */
}

.SFTPSecrets,
.pgpSecrets,
.DBSecrets {
  border: 2px solid #d2d2d2; /* Default border */
}

.SFTPSecrets:hover,
.pgpSecrets:hover,
.DBSecrets:hover {
  background-color: rgba(88, 219, 255, 0.1); /* Transparent blue background */
  border: 2px solid #00c8ff; /* Blue border on hover */
  cursor: pointer;
}

/* Hover effect for icons - change icon color to blue */
.SFTPSecrets:hover i.pi-server,
.DBSecrets:hover i.pi-database,
.SFTPSecrets:hover i.pi-angle-right,
.DBSecrets:hover i.pi-angle-right {
  color: #0e7490; /* Icon color on hover */
}

/* Exclude border from the inner content divs */
.SFTPSecrets > div,
.DBSecrets > div {
  border: none; /* Ensure inner divs don't inherit the border */
}

/* General dialog box and layout styles */
.dialog-container {
  width: 100%;
  max-width: 1200px; /* Max width for large screens */
  margin: 0 auto;
  padding: 2rem;
}

/* Form fields flex container */
.form-fields {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
  margin: 0 auto;
}

/* Column style for each environment (PROD, DEV, QA) */
.column {
  flex: 1 1 30%;
  min-width: 300px; /* Ensure columns don't shrink too small */
  box-sizing: border-box;
}

/* Ensure the password field also scales properly */
.p-field .p-password {
  width: 100%;
}

/* For smaller screens (e.g., mobile), adjust the columns to stack vertically */
@media screen and (max-width: 768px) {
  .form-fields {
    flex-direction: column; /* Stack the columns */
    gap: 1rem;
  }
  .column {
    flex: 1 1 100%; /* Each column takes full width */
    min-width: unset; /* Remove min-width to ensure full width */
  }
}
