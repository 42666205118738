.maindiv{
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 10px;
    margin-top: rem;
    max-height: 70vh;
    overflow-y: auto;
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    /* border: 2px solid red; */
    margin: 1rem;
    margin-top: 1rem;
}
 td .edit_button:hover
{
    font-size: large;
    cursor: pointer;
    color: #ff4200;

}
.maindiv .listtable
{
    width: 100%;
    max-width: 66rem;
    margin: auto;
    box-sizing: border-box;
    margin-top: 2rem;
}