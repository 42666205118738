@import "./fonts.scss";

:root {
  --active-color: #ff8b00;
  --primary: #ff8b00;
  --primary-color: #ff8b00 !important;

  --primary-50: #fbe9e7 !important;
  --primary-100: #ffccbc !important;
  --primary-200: #ffab91 !important;
  --primary-300: #ff8a65 !important;
  --primary-400: #ff7043 !important;
  --primary-500: #ff5722 !important;
  --primary-600: #f4511e !important;
  --primary-700: #e64a19 !important;
  --primary-800: #d84315 !important;
  --primary-900: #bf360c !important;
}

* {
  font-family: "archiaregular";
}

html {
  overflow-x: hidden;
  width: 100vw;
}

.p-button.p-button-primary {
  background: var(--primary);
  border: 1px solid var(--primary);
  &:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--primary-100),
      0 1px 2px 0 rgb(0, 0, 0);
  }
}
.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.2rem var(--primary-100);
  border-color: var(--primary);
}

body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 3px;
}

::-webkit-scrollbar-track {
  height: 5px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  height: 3px;
  background: #41617d !important;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;
}

.app {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.img-contain {
  object-fit: contain;
  -o-object-fit: contain;
  object-position: center;
  -o-object-position: center;
}

.img-cover {
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
}

.bg-cover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-contain {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.login-logo,
.logo-header {
  @extend .img-contain;
}

.login-logo {
  height: 70px;
  width: 165px;
  border-radius: 15px;
  overflow: hidden;
  // box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
}

.logo-header {
  height: 45px;
  width: 120px;
  border-radius: 15px;
  overflow: hidden;
  // box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  background-image: url("../assets/images/logo.png");
  @extend .bg-cover;
}

.pi-logo-icon {
  height: 56px;
  min-width: 56px;
  border-radius: 6px 6px 0 0;
  background-image: url("../assets/images/logo-icon.jpg");
  @extend .bg-cover;
}

.logo-icon-header {
  height: 48px;
  width: 48px;
  @extend .img-cover;
}

.p-float-label.w-full {
  .p-password,
  .p-password input {
    width: 100%;
  }
}
.p-dropdown-items {
  margin: 0 !important;
  padding: 0 !important;
  list-style-type: none !important;
}
.container {
  width: calc(100% - 90px - 2%);
  margin-left: 90px;
  margin-right: 2%;
}

.active-text {
  color: var(--active-color);
}

.p-password.w-full {
  .p-icon-field,
  .p-password-input {
    width: 100%;
  }
}
ul.menu {
  li {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    a {
      padding: 0.4rem 0.8rem;
    }
  }
}

.p-selectbutton {
  &.p-selectbutton-xs {
    .p-button {
      font-size: small;
    }
  }
}

#popup_menu_right,
.p-multiselect-panel,
.p-column-filter-overlay {
  ul.p-menu-list,
  ul.p-multiselect-items,
  ul.p-column-filter-row-items {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      a {
        text-decoration: none;
      }
    }
  }
}

.i-20px,
.p-button.i-20px .p-button-icon {
  font-size: 20px;
}

.mt-m1 {
  margin-top: -1rem;
}

.w-auto-control input {
  max-width: 100%;
  width: 100%;
}
