/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.App-logo {
  height: 40vmin; /* Logo height relative to the viewport */
  pointer-events: none; /* Prevent interactions with the logo */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear; /* Spinning animation */
  }
}

.App-header {
  background-color: #282c34; /* Dark background for header */
  min-height: 100vh; /* Minimum height to fill the viewport */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  font-size: calc(10px + 2vmin); /* Responsive font size */
  color: white; /* White text color */
  padding: 20px; /* Add some padding for better spacing */
  text-align: center; /* Center text */
}

.App-link {
  color: #61dafb; /* Link color */
  text-decoration: none; /* Remove underline from links */
}

.App-link:hover {
  text-decoration: underline; /* Underline on hover for better accessibility */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); /* Start at 0 degrees */
  }
  to {
    transform: rotate(360deg); /* Rotate to 360 degrees */
  }
}
