/***************Create Rules***************************/

.rule-box-3 {
  width: 100%;
  height: 450px;
}

.iconimage {
  margin: 0 auto;
}

.iconimage,
.iconimage img {
  width: 128px;
  height: 128px;
}

.iconimage img {
  object-fit: contain;
  object-position: center;
}

.servicename {
  color: #1689e0;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the spinner appears on top */
}
