/*   ******************************************************************************************************** */

.header-font {
  font-size: 30px;
  padding: 10px;
  margin: 0 10px 10px 10px;
  /* text-decoration: underline;   */
}

.back-ground-color {
  background-color: white;
  /* height: 100vh; */
  /* text-align: left; */
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
}

.label-input {
  display: inline-block; /* Ensures labels and input boxes are displayed on the same line */
  width: 30%; /* Adjust the width of labels to accommodate the longest label */
  margin-left: 10px; /* Add left margin to labels for spacing */
  text-align: left; /* Align labels to the left */
}

/* .label-center{
    text-align: center;
} */

.dinput {
  width: 50%;
  outline: none;
  border: none;
  background-color: #cecece;
  /* background-color: #E2F2F6; */
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  margin-bottom: 20px;
  margin-left: -40px;
}

.btn-submit {
  /* margin: auto; */
  margin-top: 30px;
  width: 120px !important;
  padding: 5px;
  border: none;
  transform: translate(-30%);
  color: white;
  background-color: rgb(34, 183, 241);
}

/* .form-div {
    width: 80%;
    margin: auto;
    padding: 10px;
    padding-bottom: 4rem;
    box-shadow: 0px 0px 10px 10px #ccc;
} */

/* .text-right{
    text-align: right;
} */

/* .text-left{
    text-align: left;
} */

.font-style-p {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

/* ***********************Back Button************************************** */

.heading {
  display: flex;
  margin-bottom: 20px;
}

.BackButton {
  text-align: left;
}

.arrowIcon {
  font-size: 42px; /* Adjust the size of the arrow icon */
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Makes the icon circular */
  background-color: #e0e0e0; /* Background color for the circle */
  padding: 8px; /* Padding inside the circle */
  margin-right: auto; /* Space between the arrow and the title */
  padding: 10px;
  margin: 10px 10px 10px;
}

.name {
  margin: 0; /* Remove default margin of the h1 element */
  font-size: 36px;
}

.titleH1 {
  flex: 1; /* Take up remaining space */
  text-align: center; /* Center the title text */
  padding: 10px;
  margin: 0 10px 10px 10px;
}

/*******************************Button Color ****************************** */

.red {
  color: red;
  width: 60px;
}
.box-view {
  width: 300px;
  display: flex;
  justify-content: space-evenly;
}
.green {
  color: green;
  width: 60px;
}

.show {
  display: inline-block;
}
.hide {
  display: none;
}

/******************************************* check box ************************ */

.checkbox {
  width: 50%;
  background-color: #cecece;

  border-radius: 5px;
  margin: 5px;
  /* padding: 5px ; */
  text-align: left;
  /* margin-top: 5px ; */
  /* margin-left: -40px; */
}

.nameAndPush {
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
}

.migrateDrop {
  border: 2px solid #0be1e6;
}
.current-view {
  /* border: 2px solid blue; */
  /* border: 2px solid blue; */
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.circleInd {
  /* border: 2px solid red; */
  margin-left: auto;
  margin-right: auto;
  width: 6rem;
}
.viewDd {
  /* border: 2px solid black; */
  width: 8rem;
}


.no-highlight:focus {
  outline: none !important; /* Prevents any focus outline */
  box-shadow: none !important; /* Removes focus box-shadow */
  background-color: inherit; /* Ensures no background change */
}