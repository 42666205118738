.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
}
.card {
  margin-top: 30px;
}

.underline-text {
  display: inline-block;
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.3s;
  cursor: pointer;
  /* text-decoration: underline; */
}
